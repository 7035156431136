
import { Component, Vue } from "vue-property-decorator";
import User from "@/store/modules/User";
import { IUserLoginRequestParams } from "@/services/models";
import { required, email } from "vuelidate/lib/validators";

@Component({
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
})
export default class LoginForm extends Vue {
  form: IUserLoginRequestParams = { email: "", password: "" };
  isLoading = false;
  errors: string[] = [];

  async login(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.errors = [];
      try {
        this.isLoading = true;
        await User.login(this.form);
        this.$router.push({ name: this.$routesNames.home });
      } catch (error) {
        console.log(error);
        this.errors = error;
      } finally {
        this.isLoading = false;
      }
    }
  }
}
